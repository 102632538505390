<template>
  <div>
    <div class="valueWrap">
      <div v-if="!editInputShow">
        <div v-if="bidAmountValue">
          <span>{{ formatedMoney(bidAmountValue) }}</span>
          <i
            class="el-icon-edit-outline"
            @click.stop="editAmount"
          ></i>
        </div>
        <div v-else>--</div>
      </div>
      <div v-else>
        <el-input
          v-model="newValue"
          @blur="save"
          ref="input"
          size="mini"
        ></el-input>
      </div>
    </div>
    <div class="valueName">
      {{ valueName }}
      <el-tag
        size="mini"
        style="cursor: pointer"
        @click="setTimeEditBidHandle"
        v-show="level == 'adset' && bidAmountValue"
        >定时改出价</el-tag
      >
    </div>
    <set-time-edit-bid
      ref="setTimeEditBid"
      :row="row"
      :level="level"
      v-on="$listeners"
    ></set-time-edit-bid>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mutatePricing } from '@/api/adManagement';
import setTimeEditBid from '@/views/adManagement/components/table/setTimeEditBid';
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: 'campaign',
    },
  },
  components: {
    setTimeEditBid,
  },
  computed: {
    ...mapGetters(['toFormatedMoney', 'toBaseMoney', 'toBaseRoas', 'toFormatedRoas']),
    valueName() {
      let name = '';
      switch (this.value.row.bid_strategy) {
        case 'LOWEST_COST_WITHOUT_CAP':
          name = '自动竞价';
          break;
        case 'COST_CAP':
          name = '目标费用';
          break;
        case 'LOWEST_COST_WITH_BID_CAP':
          name = '手动出价';
          break;
        case 'LOWEST_COST_WITH_MIN_ROAS':
          name = '最低roas';
          break;
      }
      return name;
    },
    bidAmountValue: {
      get() {
        return this.value.row.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS'
          ? this.value.row.roas_average_floor
          : this.value.row.bid_amount;
      },
      set() {},
    },
    headers() {
      return {
        adAccountId: this.accountId,
        thirdLoginUserId: this.value.row.rowThirdNum,
      };
    },
    row() {
      return {
        ...this.value.row,
        campaignId: this.value.row.campaign_id,
        adGroupId: this.value.row.adset_id,
        adGroupAdId: this.value.row.adset_id,
      };
    },
  },
  data() {
    return {
      editInputShow: false,
      newValue: '',
    };
  },
  methods: {
    formatRoas(val) {
      return val / 1;
    },
    baseRoas(val) {
      return val * 1;
    },
    formatedMoney(v) {
      if (this.value.row.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS') {
        return this.bidAmountValue;
      }
      return this.toFormatedMoney(this.bidAmountValue).formated || '';
    },
    editAmount() {
      this.editInputShow = true;
      this.newValue =
        this.value.row.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS'
          ? this.bidAmountValue
          : this.$store.getters.toFormatedMoney(this.value.row.bid_amount).value;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    refreshTableRow() {
      let scope = this.value;
      scope.row.bid_amount = this.toBaseMoney(this.newValue);
      this.value.row.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS' ? this.newValue : this.toBaseMoney(this.newValue);
      if (this.value.row.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS') {
        scope.row.roas_average_floor = (this.newValue / 1).toFixed(2);
      } else {
        scope.row.bid_amount = this.toBaseMoney(this.newValue);
      }
      this.$emit('scope-row-changed', scope);
    },
    save() {
      if (isNaN(this.newValue)) {
        this.$message({
          type: 'warning',
          message: '请输入数字',
        });
        return false;
      }
      console.log(this.newValue);
      let value =
        this.value.row.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS'
          ? (this.newValue / 1).toFixed(2)
          : this.toBaseMoney(this.newValue);
      let obj = {
        id: this.value.row.id,
        bid_strategy: this.value.row.bid_strategy,
      };
      if (this.value.row.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS') {
        obj.roas_average_floor = value;
        obj.before_roas_average_floor = this.value.row.roas_average_floor;
      } else {
        obj.before_amount = this.value.row.bid_amount;
        obj.bid_amount = value;
      }
      let params = {
        objects: [obj],
        level: this.level,
      };
      this.$showLoading();
      mutatePricing(JSON.stringify(params), this.headers).then((res) => {
        // console.log(res)
        this.$hideLoading();
        if (res.code == 0) {
          this.editInputShow = false;
        } else {
          this.$message({
            type: 'warning',
            message: '失败了',
          });
        }
        this.refreshTableRow();
      });
      this.editInputShow = false;
    },
    // 设置定时改出价
    setTimeEditBidHandle() {
      this.$refs.setTimeEditBid.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.valueWrap {
  i {
    margin-left: 5px;
  }
}

.valueName {
  font-size: 12px;
  color: #999;
}
</style>
